import axios from "../network/AxiosInstance";
import {AuthService} from "./AuthService";
import {AccountFilter} from "../models/filters/AccountFilter";
import {AccountDTO} from "../models/AccountDTO";
import {AccountCreationDTO} from "../models/AccountCreationDTO";
import {Page} from "../pagination/Page";
import axiosInstance from "../network/AxiosInstance";
import {AccountStatus} from "../models/AccountStatus";
import { AccountReducedDTO } from "../models/AccountReducedDTO";
import {SortingConfiguration} from "../utils/SortingUtils";
import {AccountDetailedStatusDTO} from "../models/AccountDetailedStatusDTO";
import {AccountDeletionDTO} from "../models/AccountDeletionDTO";
import {AccountSwitchDTO} from "../models/AccountSwitchDTO";
import {AccountTransferDTO} from "../models/AccountTransferDTO";

export class AccountService {
    static async getAllAccountsPaged(page: number, size: number, accountFilter: AccountFilter, sortingConfiguration: SortingConfiguration | null): Promise<Page<AccountDTO>> {
        const params = new URLSearchParams({
            page: page.toString(),
            size: size.toString()
        });

        sortingConfiguration?.appendToBackEndSearchParams(params);


        const response = await axios.post<Page<AccountDTO>>(
            `${AuthService.PREFIX}/bot-session/account/all-paged`, accountFilter, {params: params},
        );
        const data = response.data;

        return new Page(
            data.content.map((account) => new AccountDTO(account)),
            data.totalElements,
        );
    }

    static async getAccountById(id: number): Promise<AccountDTO> {
        const response = await axios.get<AccountDTO>(`${AuthService.PREFIX}/bot-session/account/${id}`, {});
        const data = response.data;

        return new AccountDTO(data);
    }

    static async deleteAccount(id: number | undefined): Promise<void> {
        await axios.delete<AccountDTO>(`${AuthService.PREFIX}/bot-session/account/delete/${id}`, {params: {id}});
    }

    static async deleteMultipleAccounts(accountsToBeDeleted: AccountDeletionDTO): Promise<void> {
        await axios.post<AccountDeletionDTO>(`/rest/bot-session/account/delete-multiple-accounts`, accountsToBeDeleted)
    }

    static async switchAccountsToAccountGroup(accountsToSwitch: AccountSwitchDTO): Promise<void> {
        await axios.post<AccountSwitchDTO>(`/rest/bot-session/account/switch-accounts-to-account-group`, accountsToSwitch)
    }

    static async transferAccountsToAccountGroup(accountsToTransfer: AccountTransferDTO): Promise<void> {
        await axios.post<AccountTransferDTO>(`/rest/bot-session/account/transfer-accounts-to-account-group`, accountsToTransfer)
    }

    static async createAccount(account: AccountCreationDTO): Promise<AccountDTO> {
        const response = await axios.post<AccountDTO>(
            `${AuthService.PREFIX}/bot-session/account/create`, account,);

        const data = response.data;
        return new AccountDTO(data);
    }

    static async getAccountWorkingStatus(id: number): Promise<AccountStatus> {
        const response = await axiosInstance.get<AccountStatus>(`${AuthService.PREFIX}/bot-session/account/working-status/${id}`,);
        const data = response.data;
        return new AccountStatus(data);
    }

    static async editAccount(account: AccountCreationDTO, id: number): Promise<AccountDTO> {
        const response = await axios.post<AccountDTO>(
            `${AuthService.PREFIX}/bot-session/account/update/${id}`, account,);

        const data = response.data;
        return new AccountDTO(data);
    }

    static async getAllOfGroup(accountGroupId: number): Promise<AccountReducedDTO> {
        const response = await axios.get<AccountReducedDTO>(`${AuthService.PREFIX}/bot-session/account/all-of-group/${accountGroupId}`);
        const data = response.data;
        return data;
      }

    static async getAccountDetailedStatus(id: number): Promise<AccountDetailedStatusDTO> {
        const response = await axios.get<AccountDetailedStatusDTO>(
            `/rest/bot-session/account/${id}/detailed-status/`
        );
        const data = response.data;

        return new AccountDetailedStatusDTO(data);
    }
    static async importFromExcel(accountGroupId: number | undefined,serviceId:number, file: any) {
        let formData = new FormData();
        formData.append("file", file)

        const params = new URLSearchParams();

        if (accountGroupId) {
            params.append("accountGroupId", accountGroupId.toString());
        }

        if (serviceId) {
            params.append("serviceId", serviceId.toString());
        }

        return await axiosInstance.post(`${AuthService.PREFIX}/bot-session/account/import`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            params: params,
        });
    }

    static async importForAccountCreation(accountGroupId: number | undefined,serviceId:number, regionId: number, file: any, proxyId?: number, creationProxyId?: number, creationAccountGroupId?: number) {
        let formData = new FormData();
        formData.append("file", file)

        const params = new URLSearchParams();

        if (accountGroupId) {
            params.append("accountGroupId", accountGroupId.toString());
        }

        if (serviceId) {
            params.append("serviceId", serviceId.toString());
        }

        if (regionId) {
            params.append("regionId", regionId.toString());
        }

        if (proxyId) {
            params.append("proxyId", proxyId.toString());
        }

        if (creationProxyId) {
            params.append("creationProxyId", creationProxyId.toString());
        }

        if (creationAccountGroupId) {
            params.append("creationAccountGroupId", creationAccountGroupId.toString());
        }

        return await axiosInstance.post(`${AuthService.PREFIX}/bot-session/account/import-for-creation`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            params: params,
        });
    }

    static async getPercentageAccountsWithListenedTracks(serviceId: number | undefined, dateFrom: string | null, dateTo: string | null): Promise<number> {
        const params = new URLSearchParams();

        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axios.get<number>(
            `${AuthService.PREFIX}/bot-session/account/percentage-accounts-with-listened-tracks`, {params: params}
        );
        return response.data;
    }

    static async getPercentageWithIncorrectPassword(serviceId: number | undefined, dateFrom: string | null, dateTo: string | null): Promise<number> {
        const params = new URLSearchParams();

        if(serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if(dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if(dateTo) {
            params.append("dateTo", dateTo);
        }

        const response = await axios.get<number>(
            `${AuthService.PREFIX}/bot-session/account/percentage-accounts-with-incorrect-password`, {params: params}
        );
        return response.data;
    }

    static async exportAccountsWithoutListenedTracks(serviceId: number | undefined, dateFrom: string | null, dateTo: string | null): Promise<Blob> {
        const params = new URLSearchParams();

        if (serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if (dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if (dateTo) {
            params.append("dateTo", dateTo);
        }

        return await axios.get(
            `${AuthService.PREFIX}/bot-session/account/export-accounts-without-listened-tracks`, { params: params, responseType: 'blob' }
        );
    }

    static async exportAccountsWithIncorrectPassword(serviceId: number | undefined, dateFrom: string | null, dateTo: string | null): Promise<Blob> {
        const params = new URLSearchParams();

        if (serviceId) {
            params.append("serviceId", serviceId.toString());
        }
        if (dateFrom) {
            params.append("dateFrom", dateFrom);
        }
        if (dateTo) {
            params.append("dateTo", dateTo);
        }

        return await axios.get(
            `${AuthService.PREFIX}/bot-session/account/export-accounts-with-incorrect-password`, { params: params, responseType: 'blob' }
        );
    }
}
