import * as React from 'react';
import { ReactElement } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { strings } from "../localization/Localization";
import { Routes } from "../router/Routes";
import { AccountCircle } from "@mui/icons-material";
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StorageIcon from '@mui/icons-material/Storage';
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { StorageService } from "../storage/StorageService"
import { SideBar } from "./SideBar";
import { SideBarItem } from '../models/SideBarItem';
import BuildIcon from '@mui/icons-material/Build';
import HistoryIcon from '@mui/icons-material/History';
import DvrIcon from '@mui/icons-material/Dvr';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LyricsIcon from '@mui/icons-material/Lyrics';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { BreadcrumbsComponent } from './BreadcrumbsComponent';
import { BreadcrumbsModel } from "../models/BreadcrumbsModel";
import LogoutIcon from '@mui/icons-material/Logout';
import { Privileges } from "../models/nomenclatures/Privileges";
import { PrivilegeGuard, PrivilegeGuardData, PrivilegeGuardMode } from "../router/guards/PrivilegeGuard";
import TableChartIcon from '@mui/icons-material/TableChart';
import PublicIcon from '@mui/icons-material/Public';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Groups3Icon from '@mui/icons-material/Groups3';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import AlbumIcon from '@mui/icons-material/Album';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import AnalyticsIcon from '@mui/icons-material/Analytics';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}
const MainContent = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

interface PersistentDrawerLeftProps {
    title: string;
    breadcrumbs?: BreadcrumbsModel[];
    component: ReactElement;
}

export default function PersistentDrawerLeft(props: PersistentDrawerLeftProps) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const auth = true;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        StorageService.clearAuthToken()
        window.location.href = "/"
    }

    //maybe we can use it when page is open
    function buildItems(): SideBarItem[] {
        return [
            new SideBarItem(
                strings.dashboard,
                Routes.HOME,
                <DashboardIcon />,
                []
            ),
            new SideBarItem(
                strings.clientDashboard,
                Routes.CLIENT_DASHBOARD,
                <TableChartIcon />,
                []
            ),
            new SideBarItem(
                strings.botScenariosAndSessions,
                Routes.SESSIONS,
                <SmartToyOutlinedIcon />,
                [
                    new SideBarItem(
                        strings.sessions,
                        Routes.SESSIONS,
                        <DvrIcon />,
                        [

                        ],
                        new PrivilegeGuardData(
                            [
                                Privileges.READ_SESSIONS,
                                Privileges.READ_SESSION_INSTANCES,
                            ],
                            PrivilegeGuardMode.hasAll,
                        ),
                    ),
                    new SideBarItem(
                        strings.sessionInstances,
                        Routes.SESSION_INSTANCES,
                        <HistoryIcon />,
                        [

                        ],
                        new PrivilegeGuardData(
                            [
                                Privileges.READ_SESSION_INSTANCES,
                            ],
                            PrivilegeGuardMode.hasAll,
                        ),
                    ),
                    new SideBarItem(
                        strings.bots,
                        Routes.BOTS,
                        <SmartToyOutlinedIcon />,
                        [],
                        new PrivilegeGuardData(
                            [
                                Privileges.READ_BOTS,
                            ],
                            PrivilegeGuardMode.hasAll,
                        ),
                    ),
                    new SideBarItem(
                        strings.sharedActions,
                        Routes.SHARED_ACTIONS,
                        <PendingActionsIcon />,
                        [

                        ],
                        new PrivilegeGuardData(
                            [
                                Privileges.READ_SHARED_ACTIONS,
                            ],
                            PrivilegeGuardMode.hasAll,
                        ),
                    ),
                ],
                new PrivilegeGuardData(
                    [
                        Privileges.READ_SESSIONS,
                        Privileges.READ_SESSION_INSTANCES,
                        Privileges.READ_BOTS,
                        Privileges.READ_SHARED_ACTIONS,
                    ],
                    PrivilegeGuardMode.hasAny,
                ),
            ),
            new SideBarItem(
                strings.accountGroups,
                Routes.ACCOUNT_GROUPS,
                <GroupIcon />,
                [
                    new SideBarItem(
                        strings.accountGroupsTitle,
                        Routes.ACCOUNT_GROUPS,
                        <GroupsIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_ACCOUNT_GROUPS, Privileges.READ_ACCOUNTS],
                            PrivilegeGuardMode.hasAll,
                        ),
                    ),
                    new SideBarItem(
                        strings.accounts,
                        Routes.ACCOUNTS,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuard(
                            [Privileges.READ_ACCOUNT_GROUPS, Privileges.READ_ACCOUNTS],
                            PrivilegeGuardMode.hasAll,
                        )
                    ),
                    new SideBarItem(
                        strings.problematicAccounts,
                        Routes.PROBLEMATIC_ACCOUNTS,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuard(
                            [Privileges.READ_ACCOUNT_GROUPS, Privileges.READ_ACCOUNTS],
                            PrivilegeGuardMode.hasAll,
                        )
                    ),
                    new SideBarItem(
                        strings.accountManagement,
                        Routes.ACCOUNT_MANAGEMENT,
                        <ManageAccountsIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.diagnostics,
                        Routes.DIAGNOSTICS,
                        <AnalyticsIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                ],
                new PrivilegeGuardData(
                    [Privileges.READ_ACCOUNT_GROUPS, Privileges.READ_ACCOUNTS],
                    PrivilegeGuardMode.hasAll,
                ),
            ),
            new SideBarItem(
                strings.proxies,
                Routes.PROXIES,
                <StorageIcon />,
                [
                    new SideBarItem(
                        strings.proxies,
                        Routes.PROXIES,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_PROXIES, Privileges.MANAGE_PROXY],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.proxyGroups,
                        Routes.PROXY_GROUPS,
                        <Groups3Icon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_PROXIES, Privileges.MANAGE_PROXY],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.massiveProxyEdit,
                        Routes.PROXY_MASSIVE_EDIT,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_PROXIES, Privileges.MANAGE_PROXY],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.mobileProxies,
                        Routes.MOBILE_PROXIES,
                        <PhonelinkSetupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_PROXIES, Privileges.READ_MOBILE_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.proxyToProxy,
                        Routes.PROXY_TO_PROXY,
                        <SocialDistanceIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_PROXIES, Privileges.MANAGE_PROXY],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                ],
                new PrivilegeGuardData(
                    [Privileges.READ_PROXIES],
                    PrivilegeGuardMode.hasAll
                )
            ),
            new SideBarItem(
                strings.regions,
                Routes.REGIONS,
                <PublicIcon />,
                [],
                new PrivilegeGuardData(
                    [Privileges.READ_REGIONS],
                    PrivilegeGuardMode.hasAll
                )
            ),

            new SideBarItem(
                strings.playlists,
                Routes.PLAYLISTS,
                <PlaylistAddCheckIcon />,
                [
                    new SideBarItem(
                        strings.playlistsForGeneration,
                        Routes.PLAYLISTS_FOR_GENERATION,
                        <PlaylistAddIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.MANAGE_TARGET_PLAYLISTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.genres,
                        Routes.GENRES,
                        <ShuffleIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.MANAGE_TARGET_PLAYLISTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.recordLabels,
                        Routes.RECORD_LABEL,
                        <AlbumIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.MANAGE_TARGET_PLAYLISTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                ],
                new PrivilegeGuardData(
                    [Privileges.MANAGE_TARGET_PLAYLISTS],
                    PrivilegeGuardMode.hasAll
                )
            ),
            new SideBarItem(
                strings.queues,
                Routes.QUEUES,
                <AddToQueueIcon />,
                [],
                new PrivilegeGuardData(
                    [Privileges.READ_QUEUES],
                    PrivilegeGuardMode.hasAll
                )
            ),
            new SideBarItem(
                strings.reports,
                Routes.REPORTS,
                <StorageIcon />,
                [
                    new SideBarItem(
                        strings.totalExecutedSessionInstances,
                        Routes.REPORT_TOTAL_EXECUTED_SESSION_INSTANCES,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.accountsThatHaveAtLeastOneListenedTrack,
                        Routes.REPORT_ACCOUNTS_AT_LEAST_ONE_LISTENED_TRACK,
                        <PhonelinkSetupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.accountsWithlastSuccessfulSession,
                        Routes.REPORT_ACCOUNTS_LAST_SUCCESSFUL_SESSION,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.accountsWithNoListenedTracks,
                        Routes.REPORT_ACCOUNTS_NO_LISTENED_TRACKS,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.accountsThatSwitchedAccountGroup,
                        Routes.REPORT_ACCOUNT_SWITCHED_ACCOUNT_GROUP,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.listenedTracksPerArtist,
                        Routes.REPORT_LISTENED_TRACKS_PER_ARTIST,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.totalListenedTracksPerHour,
                        Routes.LISTENED_TRACKS_BY_HOUR,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.problematicBots,
                        Routes.PROBLEMATIC_BOTS,
                        <GroupIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                ],
            ),
            new SideBarItem(
                strings.phones,
                Routes.MOBILE_PHONES,
                <PhonelinkSetupIcon />,
                [],
                new PrivilegeGuardData(
                    [Privileges.READ_MOBILE_PHONE_STATUSES],
                    PrivilegeGuardMode.hasAll
                )
            ),
            new SideBarItem(
                strings.administration,
                "",
                <BuildIcon />,
                [
                    new SideBarItem(
                        strings.users,
                        Routes.USERS,
                        <AccountCircleIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_USERS],
                            PrivilegeGuardMode.hasAll
                        )
                    ),
                    new SideBarItem(
                        strings.clients,
                        Routes.CLIENTS,
                        <SentimentVerySatisfiedIcon />,
                        [],
                        new PrivilegeGuardData(
                            [Privileges.READ_CLIENTS],
                            PrivilegeGuardMode.hasAll
                        )
                    )
                ],
                new PrivilegeGuardData(
                    [Privileges.READ_USERS, Privileges.READ_CLIENTS],
                    PrivilegeGuardMode.hasAny,
                ),
            ),
            new SideBarItem(
                strings.listenedTracks,
                Routes.LISTENED_TRACKS,
                <LyricsIcon />,
                [],
                new PrivilegeGuardData(
                    [Privileges.READ_LISTENED_TACKS, Privileges.READ_SERVICES],
                    PrivilegeGuardMode.hasAll,
                )
            ),

        ];
    }

    const items = buildItems();


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {props.title}
                        </Typography>
                        {auth && (
                            <div>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        onClick={handleLogout}
                                    >
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            {strings.logout}
                                        </ListItemText>
                                    </MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>

                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="persistent"
                    anchor="left"
                    open={open}
                >
                    <DrawerHeader>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    {items.map((sideBarItem, index) => (
                        <SideBar item={sideBarItem} key={index} />
                    ))}
                </Drawer>
                <MainContent open={open}>
                    <DrawerHeader />
                    <BreadcrumbsComponent listLinks={props.breadcrumbs} />
                    {props.component}
                </MainContent>
            </Box>
        </>
    );
}
