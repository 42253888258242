export class FileDownloadUtils {
    static getFileName(disposition: string): string | null {
        // Thanks to https://stackoverflow.com/a/67994693
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

        let fileName: string | null = null;
        if (utf8FilenameRegex.test(disposition)) {
            fileName = decodeURIComponent(
                utf8FilenameRegex.exec(disposition)![1],
            );
        } else {
            // prevent ReDos attacks by anchoring the ascii regex to string start and
            //  slicing off everything before 'filename='
            const filenameStart = disposition
                .toLowerCase()
                .indexOf('filename=');
            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart);
                const matches = asciiFilenameRegex.exec(partialDisposition);
                if (matches != null && matches[2]) {
                    fileName = matches[2];
                }
            }
        }

        return fileName;
    }

    static downloadFile(response: any): void {
        let blob = response.data;
        const fileName = this.getFileName(
            response.headers['content-disposition'],
        );
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileName ? fileName : '';
        alink.click();
    }
}
