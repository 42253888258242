import { createBrowserRouter } from "react-router-dom";
import { ErrorRoute } from "../pages/error/ErrorRoute";
import { Login } from "../pages/login/Login";
import { GuardedRoute } from "./guards/GuardedRoute";
import { AuthGuard } from "./guards/AuthGuard";
import { NonAuthGuard } from "./guards/NonAuthGuard";
import { Routes } from "./Routes";
import { Proxies } from "../pages/proxies/Proxies";
import { ProxyNew } from "../pages/proxies/ProxyNew";
import { ProxyDetails } from "../pages/proxies/ProxyDetails";
import { Sessions } from "../pages/sessions/Sessions";
import { AddEditSession } from "../pages/sessions/AddEditSession";
import { Users } from "../pages/administration/users/Users";
import { Accounts } from "../pages/Accounts/Accounts";
import { AccountDetails } from "../pages/Accounts/AccountDetails";
import { NewAccount } from "../pages/Accounts/NewAccount";
import { SessionDetails } from "../pages/sessions/SessionDetails";
import { CreateAccountGroup } from "../pages/accountGroups/CreateAccountGroup"
import { SessionInstances } from "../pages/sessionInstances/SessionInstances";
import { AddUser } from "../pages/administration/users/AddUser";
import { EditUser } from "../pages/administration/users/EditUser";
import { ResetPassword } from "../pages/resetPassword/ResetPassword";
import { ConfirmResetPassword } from "../pages/resetPassword/ConfirmResetPassword";
import { AccountGroups } from "../pages/accountGroups/AccountGroups";
import { BotsPage } from "../pages/bot/BotsPage";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { ConfirmationPage } from "../pages/confirmationPage";
import { PaymentAccountDetails } from "../pages/PaymentAccounts/PaymentAccountDetails";
import { NewPaymentAccount } from "../pages/PaymentAccounts/NewPaymentAccount";
import { SessionInstanceDetails } from "../pages/sessionInstances/SessionInstanceDetails";
import { ListenedTracksPage } from "../pages/listenedTracks/ListenedTracksPage";
import { BotDetails } from "../pages/bot/BotDetails";
import { ListenedTrackDetails } from "../pages/listenedTracks/ListenedTrackDetails";
import { Clients } from "../pages/administration/clients/Clients";
import { SharedActions } from "../pages/sharedActions/SharedActions";
import { AddEditClient } from "../pages/administration/clients/AddEditClient";
import { ClientDetails } from "../pages/administration/clients/ClientDetails";
import { PrivilegeGuard, PrivilegeGuardMode } from "./guards/PrivilegeGuard";
import { Privileges } from "../models/nomenclatures/Privileges";
import { ClientDashboard } from "../pages/ClientDashboard/ClientDashboard"
import { ProblematicAccountDetails } from "../pages/ProblematicAccounts/ProblematicAccountDetails";
import { ProblematicAccounts } from "../pages/ProblematicAccounts/ProblematicAccounts";
import { MobilePhones } from "../pages/mobilePhones/MobilePhones";
import { MobilePhoneDetails } from "../pages/mobilePhones/MobilePhoneDetails";
import { MobileProxies } from "../pages/mobileProxies/MobileProxies";
import { Regions } from "../pages/regions/Regions";
import { RegionNew } from "../pages/regions/RegionNew";
import { RegionDetails } from "../pages/regions/RegionDetails";
import { AccountManagement } from "../pages/accountManagement/AccountManagement";
import { ReportsComponent } from "../pages/reports/ReportsComponent";
import { ProxiesMassiveEdit } from "../pages/proxies/ProxiesMassiveEdit";
import { ProxyGroups } from "../pages/proxies/proxyGroups/ProxyGroups";
import { ProxyGroupDetails } from "../pages/proxies/proxyGroups/ProxyGroupDetails";
import { ProxyGroupNew } from "../pages/proxies/proxyGroups/ProxyGroupNew";
import { Queues } from "../pages/queues/Queues";
import { QueueDetails } from "../pages/queues/QueueDetails";
import { QueueNew } from "../pages/queues/QueueNew";
import { ListenedTracksPerArtist } from "../pages/reports/ListenedTracksPerArtist";
import { TotalListenedTracksByHour } from "../pages/reports/TotalListenedTracksByHour";
import { Playlists } from "../pages/Playlists/Playlists";
import { PlaylistDetails } from "../pages/Playlists/PlaylistDetails";
import { ProblematicBots } from "../pages/reports/ProblematicBots";
import { Genres } from "../pages/Playlists/Genres/Genres";
import { GenresNew } from "../pages/Playlists/Genres/GenresNew";
import { GenresDetails } from "../pages/Playlists/Genres/GenresDetails";
import {RecordLabels} from "../pages/Playlists/RecordLabels/RecordLabels";
import {RecordLabelsNew} from "../pages/Playlists/RecordLabels/RecordLabelsNew";
import {RecordLabelsDetails} from "../pages/Playlists/RecordLabels/RecordLabelsDetails";
import {ProxyToProxy} from "../pages/proxies/proxyToProxy/ProxyToProxy";
import {ProxyToProxyDetails} from "../pages/proxies/proxyToProxy/ProxyToProxyDetails";
import {ProxyToProxyNew} from "../pages/proxies/proxyToProxy/ProxyToProxyNew";
import {Diagnostics} from "../pages/diagnostics/Diagnostics";

export function createRouter() {
    return createBrowserRouter([
        {
            path: Routes.HOME,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Dashboard />}
                />
            ),
            errorElement: <ErrorRoute />,

        },
        {
            path: Routes.CLIENT_DASHBOARD,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ClientDashboard />}
                />
            ),
            errorElement: <ErrorRoute />,

        },

        {
            path: Routes.SESSIONS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<Sessions />}
                />
            ),
            errorElement: <ErrorRoute />,

        },
        {
            path: Routes.ADD_SESSION,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<AddEditSession />}
                />
            ),
            errorElement: <ErrorRoute />,

        },
        {
            path: Routes.SESSION_INSTANCES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_SESSION_INSTANCES],
                            PrivilegeGuardMode.hasAny,
                        ),
                    ]}
                    component={<SessionInstances />}
                />
            ),
            errorElement: <ErrorRoute />,

        },
        {
            path: Routes.SESSION_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<SessionDetails />}
                />
            )
        },
        {
            path: Routes.LOGIN,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<Login />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.RESET_PASSWORD,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<ResetPassword />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.CONFIRM_RESET_PASSWORD,
            element: (
                <GuardedRoute
                    guards={[
                        new NonAuthGuard(),
                    ]}
                    component={<ConfirmResetPassword />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.PROXIES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<Proxies />}
                />
            )
        },
        {
            path: Routes.PROXY_MASSIVE_EDIT,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ProxiesMassiveEdit />}
                />
            )
        },
        {
            path: Routes.PROXY_GROUPS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ProxyGroups />}
                />
            )
        },
        {
            path: Routes.PROXY_GROUP_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ProxyGroupNew />}
                />
            )
        },
        {
            path: Routes.PROXY_GROUP_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ProxyGroupDetails />}
                />
            )
        },
        {
            path: Routes.QUEUES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_QUEUES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<Queues />}
                />
            )
        },
        {
            path: Routes.QUEUE_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_QUEUES, Privileges.MANAGE_QUEUES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<QueueNew />}
                />
            )
        },
        {
            path: Routes.EDIT_QUEUE,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_QUEUES, Privileges.MANAGE_QUEUES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<QueueDetails />}
                />
            )
        },
        {
            path: Routes.MOBILE_PROXIES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_MOBILE_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<MobileProxies />}
                />
            )
        },
        {
            path: Routes.REGIONS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_REGIONS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<Regions />}
                />
            )
        },
        {
            path: Routes.REGION_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<RegionNew />}
                />
            )
        },
        {
            path: Routes.REGION_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<RegionDetails />}
                />
            )
        },
        {
            path: Routes.ACCOUNT_MANAGEMENT,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<AccountManagement />}
                />
            )
        },
        {
            path: Routes.MOBILE_PHONES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_MOBILE_PHONE_STATUSES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<MobilePhones />}
                />
            )
        },
        {
            path: Routes.MOBILE_PHONES_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_MOBILE_PHONE_STATUSES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<MobilePhoneDetails />}
                />
            )
        },
        {
            path: Routes.BOTS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<BotsPage />}
                />
            )
        },
        {
            path: Routes.BOT_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<BotDetails />}
                />
            )
        },
        {
            path: Routes.PROXY_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ProxyNew />}
                />
            )
        },
        {
            path: Routes.PROXY_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ProxyDetails />}
                />
            )
        },
        {
            path: Routes.USERS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_USERS],
                            PrivilegeGuardMode.hasAll

                        )
                    ]}
                    component={<Users />}
                />
            )
        },
        {
            path: Routes.USER_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.CREATE_USER, Privileges.ADMINISTER_USER, Privileges.READ_ROLES, Privileges.READ_CLIENTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<AddUser />}
                />
            )
        },


        {
            path: Routes.ACCOUNTS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_ACCOUNTS],
                            PrivilegeGuardMode.hasAll
                        ),
                    ]}
                    component={<Accounts />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.PROBLEMATIC_ACCOUNTS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_ACCOUNTS],
                            PrivilegeGuardMode.hasAll
                        ),
                    ]}
                    component={<ProblematicAccounts />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
        {
            path: Routes.USER_EDIT,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<EditUser />}
                />
            )
        },

        {
            path: Routes.CREATE_ACC_GROUP,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<CreateAccountGroup />}
                />
            )
        },
        {
            path: Routes.ACCOUNT_GROUPS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_ACCOUNT_GROUPS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<AccountGroups />}
                />
            )
        },
        {
            path: Routes.ACCOUNT_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<AccountDetails />}
                />
            )
        },
        {
            path: Routes.PROBLEMATIC_ACCOUNT_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ProblematicAccountDetails />}
                />
            )
        },
        {
            path: Routes.ACCOUNT_GROUPS_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_ACCOUNT_GROUP, Privileges.READ_ACCOUNTS, Privileges.READ_SERVICES, Privileges.READ_REGIONS, Privileges.READ_AGE_GROUPS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<CreateAccountGroup />}
                />
            )
        },
        {
            path: Routes.ACCOUNT_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_ACCOUNT, Privileges.READ_ACCOUNTS, Privileges.READ_SERVICES, Privileges.READ_REGIONS, Privileges.READ_AGE_GROUPS, Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<NewAccount />}
                />
            )
        },
        {
            path: Routes.CONFIRMATION_PAGE,
            element: (
                <GuardedRoute
                    guards={[
                    ]}
                    component={<ConfirmationPage />}
                />
            )
        },

        {
            path: Routes.ACCOUNT_GROUPS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_ACCOUNT_GROUPS, Privileges.READ_ACCOUNTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<AccountGroups />}
                />
            )
        },
        {
            path: Routes.PAYMENT_ACCOUNT_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<PaymentAccountDetails />}
                />
            )
        },
        {
            path: Routes.PAYMENT_ACCOUNT_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<NewPaymentAccount />}
                />
            )
        },
        {
            path: Routes.SESSION_INSTANCE_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<SessionInstanceDetails />}
                />
            )
        },
        {
            path: Routes.CLIENTS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_CLIENTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<Clients />}
                />
            )
        },
        {
            path: Routes.CLIENTS_NEW,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<AddEditClient />}
                />
            )
        },
        {
            path: Routes.CLIENT_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ClientDetails />}
                />
            )
        },
        {
            path: Routes.LISTENED_TRACKS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_LISTENED_TACKS, Privileges.READ_SERVICES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ListenedTracksPage />}
                />
            )
        },
        {
            path: Routes.LISTENED_TRACKS_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ListenedTrackDetails />}
                />
            )
        },
        {
            path: Routes.SHARED_ACTIONS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<SharedActions />}
                />
            )
        },
        {
            path: Routes.REPORT_TOTAL_EXECUTED_SESSION_INSTANCES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ReportsComponent isTotalExecutedSessionInstances={true} />}
                />
            )
        },
        {
            path: Routes.REPORT_ACCOUNTS_NO_LISTENED_TRACKS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ReportsComponent isAccountsWithNoListenedTrack={true} />}
                />
            )
        },
        {
            path: Routes.REPORT_ACCOUNTS_LAST_SUCCESSFUL_SESSION,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ReportsComponent isAccountsWithLastSuccessfulSession={true} />}
                />
            )
        },
        {
            path: Routes.REPORT_ACCOUNTS_AT_LEAST_ONE_LISTENED_TRACK,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ReportsComponent isAccountHasAtLeastOneListenedTrack={true} />}
                />
            )
        },
        {
            path: Routes.REPORT_ACCOUNT_SWITCHED_ACCOUNT_GROUP,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ReportsComponent isAccountsThatSwitchedAccountGroup={true} />}
                />
            )
        },
        {
            path: Routes.REPORT_LISTENED_TRACKS_PER_ARTIST,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ListenedTracksPerArtist />}
                />
            )
        },
        {
            path: Routes.LISTENED_TRACKS_BY_HOUR,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<TotalListenedTracksByHour />}
                />
            )
        },
        {
            path: Routes.NOT_FOUND,
            element: (
                <ErrorRoute />
            ),
        },
        {
            path: Routes.PLAYLISTS_FOR_GENERATION,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_TARGET_PLAYLISTS, Privileges.READ_TARGET_PLAYLISTS, Privileges.MANAGE_TARGET_PLAYLIST_ITEM],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<Playlists />}
                />
            )
        },
        {
            path: Routes.GENRES,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_GENRES, Privileges.READ_GENRES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<Genres />}
                />
            )
        },
        {
            path: Routes.ADD_GENRE,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_GENRES, Privileges.READ_GENRES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<GenresNew />}
                />
            )
        },
        {
            path: Routes.EDIT_GENRE,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_GENRES, Privileges.READ_GENRES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<GenresDetails />}
                />
            )
        },
        {
            path: Routes.ADD_NEW_TARGET_PLAYLIST,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_TARGET_PLAYLISTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<PlaylistDetails />}
                />
            )
        },
        {
            path: Routes.PLAYLIST_DETAILS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_TARGET_PLAYLISTS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<PlaylistDetails />}
                />
            )
        },
        {
            path: Routes.PROBLEMATIC_BOTS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                    ]}
                    component={<ProblematicBots />}
                />
            )
        },
        {
            path: Routes.RECORD_LABEL,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_RECORD_LABELS, Privileges.READ_RECORD_LABELS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<RecordLabels />}
                />
            )
        },
        {
            path: Routes.ADD_RECORD_LABEL,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_RECORD_LABELS, Privileges.READ_RECORD_LABELS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<RecordLabelsNew />}
                />
            )
        },
        {
            path: Routes.EDIT_RECORD_LABEL,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_RECORD_LABELS, Privileges.READ_RECORD_LABELS],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<RecordLabelsDetails />}
                />
            )
        },
        {
            path: Routes.PROXY_TO_PROXY,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_PROXY, Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ProxyToProxy />}
                />
            )
        },
        {
            path: Routes.ADD_PROXY_TO_PROXY,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_PROXY, Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ProxyToProxyNew />}
                />
            )
        },
        {
            path: Routes.EDIT_PROXY_TO_PROXY,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.MANAGE_PROXY, Privileges.READ_PROXIES],
                            PrivilegeGuardMode.hasAll
                        )
                    ]}
                    component={<ProxyToProxyDetails />}
                />
            )
        },
        {
            path: Routes.DIAGNOSTICS,
            element: (
                <GuardedRoute
                    guards={[
                        new AuthGuard(),
                        new PrivilegeGuard(
                            [Privileges.READ_ACCOUNTS],
                            PrivilegeGuardMode.hasAll
                        ),
                    ]}
                    component={<Diagnostics />}
                />
            ),
            errorElement: <ErrorRoute />,
        },
    ]);
}
